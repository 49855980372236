import React, { useState } from "react";
import "./slider.scss";
import Testimonal_1 from "../Images/testimonal-1.jpg";
import Testimonal_2 from "../Images/testimonal-2.jpg";
import Testimonal_3 from "../Images/testimonal-3.jpg";
import Testimonal_4 from "../Images/testimonal-4.jpg";
import Testimonal_5 from "../Images/testimonal-5.jpg";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

const SliderImageCollection = [
  {
    id: 1,
    label: "First Picture",
    imgPath: Testimonal_1,
  },
  {
    id: 2,
    label: "Second Picture",
    imgPath: Testimonal_2,
  },
  {
    id: 3,
    label: "Third Picture",
    imgPath: Testimonal_3,
  },
  {
    id: 4,
    label: "Fourth Picture",
    imgPath: Testimonal_4,
  },
  {
    id: 5,
    label: "Fifth Picture",
    imgPath: Testimonal_5,
  }
];

const Slider = () => {
  const [value, setValue] = useState(0);

  return (
    <>
      <div className="container">
        <div className="innerContainer">
          <div id="imageContainer">
            <img
              className="testImageSlider"
              src={SliderImageCollection[value].imgPath}
              alt={SliderImageCollection[value].id}
            />
          </div>
        </div>
      </div>
      <div className="test">
      <div id="testLeftButtonDiv">
              <button
                className="testomonialButtons"
                type="button"
                onClick={() =>
                  value === 0 ? setValue(4) : setValue(value - 1)
                }
              >
                <BsArrowLeft />
              </button>
            </div>

            <div id="testRightButtonDiv">
              <button
                className="testomonialButtons"
                type="button"
                onClick={() =>
                  value === 4 ? setValue(0) : setValue(value + 1)
                }
              >
                <BsArrowRight />
              </button>
            </div>
      </div>
    </>
  );
};

export default Slider;
