import React from "react";
import PackageItem from "../../Components/lifeStylePackageItem";
import BrandsPhoto from "../../Images/brand-and-events.jpg";
import ElopementPhoto from "../../Images/elopement.jpg";
import NewBornPhoto from "../../Images/newborn.jpg";
import MaternityPhoto from "../../Images/maternity.jpg";
import FamilyPhoto from "../../Images/family.jpg";
import YouAndMePhoto from "../../Images/you-and-me.jpg";
import { Helmet } from "react-helmet";
import InvestmentCoverImage from "../../Images/family-investment-page.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

function LifeStyle() {
	const packages = [
		{
			name: "Maternity",
			inclusions: [
				"60min session",
				"A completed album of your edited images (minimum 30 images)",
				"Private digital gallery for download",
				"Option to upgrade to 'Bump to Babe' package",
				"Unlimited contact leading up to the session",
			],
			image: MaternityPhoto,
		},
		{
			name: "Newborn",
			inclusions: [
				"60min session",
				"A completed album of your edited images (minimum 30 images)",
				"Private digital gallery for download",
				"Option for indoor or outdoor session",
				"Unlimited contact leading up to the session",
				"Option to upgrade to 'Bump to Babe' package",
			],
			image: NewBornPhoto,
		},
		{
			name: "Family",
			inclusions: [
				"60min session",
				"A completed album of your edited images (minimum 30 images)",
				"A private digital gallery for download",
				"Option for indoor or outdoor session",
				"Unlimited contact leading up to the session",
				"Contact for extended family package",
			],
			image: FamilyPhoto,
		},
		{
			name: "You + Me",
			inclusions: [
				"60min session",
				"A completed album of your edited images (minimum 30 images0",
				"A private digital gallery for download",
				"Unlimited contact leading up to the session",
			],
			image: YouAndMePhoto,
		},
		{
			name: "Elopement",
			inclusions: ["Please contact me for package information"],
			image: ElopementPhoto,
		},
		{
			name: "Brands + Events",
			inclusions: ["Please contact me for package information"],
			image: BrandsPhoto,
		},
	];
	return (
		<div id="life-style-">
			<Helmet>
				<title>Lifestyle photo shoots with Stories By Chey Photography</title>
				<meta
					name="description"
					content="Brisbane photograher looking to capture your next special life event"
				/>
			</Helmet>

			<div>
				<img id="lifestyle-packages-header-image" src={InvestmentCoverImage} />
			</div>

			<Box sx={{ flexGrow: 1 }} id="life-style-packages-div">
				<Grid
					container
					direction="row"
					justifyContent="center">
					{packages.map((lifeStylePackage) => (
						<Grid item>
							<PackageItem
								key={lifeStylePackage.name}
								title={lifeStylePackage.name}
								inclusions={lifeStylePackage.inclusions}
								image={lifeStylePackage.image}
							/>
						</Grid>
					))}
				</Grid>
			</Box>
		</div>
	);
}
export default LifeStyle;
