import React, { useEffect } from "react";
import { useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/home";
import About from "./Pages/about";
import ContactMe from "./Pages/contactMe";
import Packages from "./Pages/Investments/packages";
import Portfolio from "./Pages/portfolio";
import LifeStyle from "./Pages/Investments/lifestyle";
import Blog from "./Pages/Blog/Blog";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import './assets/fonts.css';
import BlogPost from "./Pages/BlogPost/BlogPost";
import BlogCreation from "./Pages/BlogCreation/BlogCreation";
import HeaderController from "./Components/headerController";
import Footer from "./Components/footer";
import ProtectedRoute from "./utils/ProtectedRoute";
import Login from "./Pages/Login/Login";
import { AuthContext } from "./Context/AuthContext";
import { Admin } from "./Pages/AdminPage/Admin";

const PageLayout = ({ children }) => {
	return (
		<>
			<HeaderController />
			{children}
			<Footer />
		</>
	);
};

const theme = createTheme({
	typography: {
		fontFamily: 'stories-by-chey-body',
		h1: {
			fontFamily: 'stories-by-chey-header',
			fontSize: "40px",
			paddingBottom: "10px",
		},
		h2: {
			fontSize: "30px",
			fontFamily: 'stories-by-chey-header'
		},
		h3: {
			fontFamily: 'stories-by-chey-header'
		},
		h5: {
			fontFamily: 'stories-by-chey-header'
		},
		body1: {
			fontSize: "20px",
			fontFamily: 'stories-by-chey-body'
		},
		allVariants: {
			textAlign: "center",
			color: "#00000099",
			fontFamily: "Billie",
			textDecoration: "none",
		},
	},
	palette: {
		primary: {
			main: "#faf9f6",
		},
		secondary: {
			main: "#828749",
		},
		button: {
			main: "#00000099",
		},
	},
	components: {
		MuiInputLabel: {
			styleOverrides: {
				root: {
					"&.MuiInputLabel-shrink": {
						color: "#00000099",
						borderColor: "#00000099",
					},
					color: "#00000099",
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					color: "#00000099",
				},
			},
		},
	},
});

function App() {
	return (
		<AuthContext>
			<ThemeProvider theme={theme}>
				<PageLayout>
					<Routes>
						<Route exact path="/" element={<Home />} />
						<Route exact path="/about" element={<About />} />
						<Route exact path="/packages" element={<Packages />} />
						<Route exact path="/contactMe" element={<ContactMe />} />
						<Route exact path="/portfolio" element={<Portfolio />} />
						<Route exact path="/lifestyle" element={<LifeStyle />} />
						<Route exact path="/blog" element={<Blog />} />
						<Route path="/blog/:blogId" element={<BlogPost />} />
						<Route
							exact
							path="/edit/blog/:blogId"
							element={
								<ProtectedRoute>
									<BlogCreation />
								</ProtectedRoute>
							}></Route>
						<Route
							exact
							path="/create/blog"
							element={
								<ProtectedRoute>
									<BlogCreation />
								</ProtectedRoute>
							}></Route>
						<Route path="/login" element={<Login />} />
						<Route
							exact
							path="/admin"
							element={
								<ProtectedRoute>
									<Admin></Admin>
								</ProtectedRoute>
							}
						/>
					</Routes>
				</PageLayout>
			</ThemeProvider>
		</AuthContext>
	);
}

export default App;
