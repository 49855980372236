import ContactForm from "../Components/contactForm";
import "./contactMe.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Helmet } from "react-helmet";

function ContactMe() {
  return (
    <div className="contactMePageBG pagePaddingTop">
      <Helmet>
				<title>Contact Photographer</title>
				<meta
					name="description"
					content="Please contact me for any information or enquiries"
				/>
			</Helmet>

      <ContactForm />
    </div>
  );
}

export default ContactMe;
