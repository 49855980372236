import React from "react";
import "./portfolio.scss";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Helmet } from "react-helmet";

const columnsCountBreakPoints = { 350: 1, 750: 2, 900: 3 };

const cache = {};

function importAll(r) {
	r.keys().forEach((key) => (cache[key] = r(key)));
}
// Note from the docs -> Warning: The arguments passed to require.context must be literals!
importAll(require.context("../Images/Portfolio/", false, /\.(png|jpe?g|svg)$/));

const images = Object.entries(cache).map((module) => module[1].default);

function Portfolio() {
	return (
		<>
			<Helmet>
				<title>Stories By Chey Photography Portfolio</title>
				<meta
					name="description"
					content="Portfolio Gallery by Stories By Chey"
				/>
			</Helmet>

			<div className="masonaryDiv">
				<ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
					<Masonry gutter={4}>
						{images.map((image) => (
							<img src={image} alt="portfolio image" className="portfolioMasonryImages" />
						))}
					</Masonry>
				</ResponsiveMasonry>
			</div>
		</>
	);
}

export default Portfolio;
