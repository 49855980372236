import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TextField from "@mui/material/TextField";
import "./BlogCreation.css";
import PublishIcon from "@mui/icons-material/Publish";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import BlogPreview from "../../Components/BlogPreview/BlogPreview";
import draftToHtml from "draftjs-to-html";
import {
	ContentState,
	EditorState,
	convertFromHTML,
	convertToRaw,
} from "draft-js";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase-config";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { getDownloadURL, uploadBytes, ref } from "firebase/storage";
import CloseIcon from "@mui/icons-material/Close";
import { v4 } from "uuid";
import { InputAdornment, Typography } from "@mui/material";
import { Context } from "../../Context/AuthContext";
import { getCurrentFormattedDate } from "../../utils/dateUtils";

function BlogCreation() {
	const { blogId } = useParams();

	const { user } = useContext(Context);

	const [title, setTitle] = useState("");
	const [subtitle, setSubtitle] = useState("");
	const [tagInputValue, setTagInputValue] = useState("");
	const [showPreview, setShowPreview] = useState(false);
	const [editorState, setEditorState] = useState(null);
	const [blogHeroImage, setBlogHeroImage] = useState();
	const [blogHeroImageName, setBlogHeroImageName] = useState(
		"Upload blog hero image here..."
	);
	const [blogHeroImageUrl, setBlogHeroImageUrl] = useState("");
	const [readTime, setReadTime] = useState("");
	const [saveButtonText, setSaveButtonText] = useState("Publish");
	const [pageHeaderText, setPageHeaderText] = useState("Create Blog");

	const [blog, setBlog] = useState({
		title: "",
		subTitle: "",
		author: {
			name: "Cheyenne Doring",
			avatarPhoto: "",
		},
		readTime: "",
		heroImage: "",
		text: "",
		tags: "",
		blogCreatedDate: getCurrentFormattedDate(),
	});

	useEffect(() => {
		const fetchPost = async () => {
			if (blogId) {
				const docRef = doc(db, "blog-posts", blogId);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					const data = docSnap.data();
					setTitle(data.title);
					setSubtitle(data.subTitle);
					setReadTime(data.readTime);
					setTagInputValue(data.tags);
					setBlogHeroImageName(data.heroImage);
					setBlogHeroImageUrl(data.heroImage);
					setEditorState(
						EditorState.createWithContent(
							ContentState.createFromBlockArray(convertFromHTML(data.text))
						)
					);
				}

				setSaveButtonText("Update");
				setPageHeaderText("Edit Blog");
			}
		};

		fetchPost();
	}, [blogId]);

	const theme = useTheme();
	const blogPostsCollectionRef = collection(db, "blog-posts");
	let navigate = useNavigate();

	useEffect(() => {
		if (editorState !== null) {
			setBlog({
				title: title,
				subTitle: subtitle,
				author: {
					name: "Cheyenne Doring",
					avatarPhoto: user.photoURL,
				},
				readTime: readTime,
				heroImage: blogHeroImageUrl,
				text: draftToHtml(convertToRaw(editorState.getCurrentContent())),
				tags: tagInputValue,
				blogCreatedDate: getCurrentFormattedDate(),
			});
		}
	}, [
		title,
		subtitle,
		tagInputValue,
		editorState,
		blogHeroImageUrl,
		readTime,
		user,
	]);

	const editorStyle = {
		fontFamily: theme.typography.fontFamily,
		color: theme.typography.allVariants.color,
	};

	const handleEditorStateChange = (newEditorState) => {
		setEditorState(newEditorState);
	};

    const handleImageUpload = async (file) => {
        return new Promise(async (resolve, reject) => {
            try {
                const storageRef = ref(storage, `blogImages/${file.name + v4()}`);
                const uploadTask = uploadBytes(storageRef, file).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((url) => {
                        resolve({ data: { link: url } });
                    });
                });
            } catch (error) {
                reject(error);
            }
        });
    };
    const toolbarOptions = {
        options: [
            "inline",
            "blockType",
            "list",
            "link",
            "embedded",
            "emoji",
            "image",
            "history",
        ],
        inline: {
            options: ["bold", "italic", "underline", "strikethrough"],
        },
        list: {
            options: ["unordered", "ordered"],
        },
        link: {
            options: ["link"],
        },
        history: {
            options: ["undo", "redo"],
        },
        image: {
            uploadCallback: handleImageUpload,
            alt: { present: true, mandatory: true },
            defaultSize: {
                height: "auto", // Set default height
                width: "100%", // Set default width
            },
        },
    };

	const handleSubmit = async () => {
		if (blogId !== null) {
			const updateBlogDocRef = doc(db, "blog-posts", blogId);
			await updateDoc(updateBlogDocRef, blog);
		} else {
			await addDoc(blogPostsCollectionRef, blog);
		}
		navigate("/blog");
	};

	const closePreview = () => {
		setShowPreview(false);
	};

	const separateTags = (event) => {
		setTagInputValue(event.target.value);
	};

	const onHeroImageUpload = (event) => {
		setBlogHeroImage(event.target.files[0]);
		setBlogHeroImageName(event.target.files[0].name);
	};

	const onHeroImageDelete = () => {
		setBlogHeroImage();
		setBlogHeroImageName("Upload blog hero image here...");
	};

	useEffect(() => {
		if (!blogHeroImage) return;

		//use uuid in the image file name
		const storageRef = ref(storage, `blogImages/${blogHeroImage.name + v4()}`);
		const uploadTask = uploadBytes(storageRef, blogHeroImage).then(
			(snapshot) => {
				getDownloadURL(snapshot.ref).then((url) => {
					setBlogHeroImageUrl(url);
				});
			}
		);
	}, [blogHeroImage]);

	const setReadTimeFunction = (event) => {
		setReadTime(event.target.value);
	};

	return (
		<>
			<Typography variant="h1">
				{pageHeaderText}
			</Typography>

			<div id="blog-creation-div">
				<TextField
					sx={{ margin: "30px 0" }}
					className="create-blog-input"
					id="create-blog-title"
					label="Blog Title"
					variant="outlined"
					onChange={(event) => setTitle(event.target.value)}
					value={title}
				/>

				<TextField
					sx={{ margin: "30px 0" }}
					className="create-blog-input"
					id="create-blog-subtitle"
					label="Blog Subtitle"
					variant="outlined"
					onChange={(event) => setSubtitle(event.target.value)}
					value={subtitle}
				/>

				<Editor
					toolbar={toolbarOptions}
					editorStyle={editorStyle}
					toolbarClassName="create-blog-toolbar-div"
					wrapperClassName="wrapperClassName"
					editorClassName="create-blog-editor-div"
					editorState={editorState}
					onEditorStateChange={handleEditorStateChange}
				/>

				<TextField
					sx={{ margin: "30px 0" }}
					className="create-blog-input"
					id="create-blog-tags-input"
					label="Add Tags (comma-separated)"
					variant="outlined"
					onChange={separateTags}
					value={tagInputValue}
				/>

				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={2}>
							<input
								type="file"
								accept="image/*"
								id="file-input"
								class="file-input"
								onChange={onHeroImageUpload}
							/>
							<label for="file-input" class="custom-file-label">
								Choose a file
							</label>
						</Grid>
						<Grid item xs={12} sm={8}>
							<TextField
								variant="outlined"
								label={blogHeroImageName}
								id="blog-hero-image-text-field"
								value={blogHeroImageName}
								fullWidth
								disabled
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Button
								onClick={onHeroImageDelete}
								variant="outlined"
								color="secondary"
								id="blog-hero-image-clear-button">
								<CloseIcon />
							</Button>
						</Grid>

						<Grid item xs={12}>
							<TextField
								variant="outlined"
								label="Blog read time..."
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">min</InputAdornment>
									),
								}}
								onChange={(event) => setReadTimeFunction(event)}
								value={readTime}
								fullWidth></TextField>
						</Grid>
					</Grid>
				</Box>

				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={2} id="blog-create-button-div">
						<Grid item xs={12} sm={6}>
							<Button
								className="blog-create-button"
								id="blog-preview-button"
								variant="outlined"
								endIcon={<VisibilityIcon />}
								onClick={() => setShowPreview(true)}
								color="secondary"
								fullWidth>
								Preview
							</Button>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button
								className="blog-create-button"
								variant="contained"
								endIcon={<PublishIcon />}
								onClick={handleSubmit}
								color="secondary"
								fullWidth>
								{saveButtonText}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</div>

			{showPreview && (
				<div>
					<BlogPreview blogData={blog} closePreview={closePreview} />
				</div>
			)}
		</>
	);
}

export default BlogCreation;
