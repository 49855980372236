import PackageItem from "../../Components/weddingPackageItem";
import WeddingPackageImage1 from "../../Images/wedding-package1.jpg";
import WeddingPackageImage2 from "../../Images/wedding-package2.jpg";
import WeddingPackageImage3 from "../../Images/wedding-package3.jpg";
import WeddingPackagesHeaderImage from "../../Images/wedding-investment-header-image.png";
import { Helmet } from "react-helmet";
import "./packages.scss";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

function packages() {
	const weddingPackageOneInclusions = [
		"Unlimited communication leading up to the day",
		"1 x coffee catch up",
		"A discounted engagement shoot",
		"Photo timeline created by us for the day",
		"The option for a keepsake box for your images at an additional cost",
		"A private online gallery of the images for your use",
	];
	const weddingPackageTwoInclusions = [
		"Unlimited communication leading up to the day",
		"1 x coffee catch up",
		"A discounted engagement shoot",
		"Photo timeline created by us for the day",
		"A keepsake box for your images at an additional cost",
		"A private online gallery of the images for your use",
	];
	const weddingPackageThreeInclusions = [
		"Unlimited communication leading up to the day",
		"2 x coffee catch up",
		"A complimentary engagement shoot",
		"1 x print from the engagement shoot",
		"Photo timeline created by us for the day",
		"A keepsake box for your images",
		"A private online gallery of the images for use by you",
	];

	return (
		<div className="pagePaddingTop" id="wedding-packages">
			<Helmet>
				<title>Wedding photo shoots with Stories By Chey Photography</title>
				<meta
					name="description"
					content="Brisbane & Sunshine Coast photograher looking to capture your dream wedding"
				/>
			</Helmet>

			<div>
				<div>
					<img
						id="wedding-packages-header-image"
						src={WeddingPackagesHeaderImage}
					/>
				</div>

				<Box sx={{ flexGrow: 1 }} id="wedding-packages-div">
					<Grid
						container
						direction="row"
						justifyContent="center"
						alignItems="center">
							
						<Grid item>
							{PackageItem(
								"To have and to hold",
								"6 hours coverage",
								"$2800",
								weddingPackageOneInclusions,
								WeddingPackageImage1,
								false
							)}
						</Grid>
						<Grid item>
							{PackageItem(
								"Now and forever",
								"8 hours coverage",
								"$3100",
								weddingPackageTwoInclusions,
								WeddingPackageImage2,
								true
							)}
						</Grid>
						<Grid item>
							{PackageItem(
								"Till death do us part",
								"10 hours coverage",
								"$3500",
								weddingPackageThreeInclusions,
								WeddingPackageImage3,
								false
							)}
						</Grid>
					</Grid>
				</Box>
			</div>
		</div>
	);
}

export default packages;
