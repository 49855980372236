import react from "react";
import "./Home/home.scss";
import "./about.scss";
import Chey from "../Images/about-me.jpg";
import { Helmet } from "react-helmet";
import { Typography } from "@mui/material";

function about() {
	return (
		<div className="pagePaddingTop">
			<Helmet>
				<title>About</title>
				<meta
					name="description"
					content="Hello gorgeous people, I am a Brisbane/Sunshine Coast based wedding and lifestyle photograher with a passion for bringing special events to life through the power of photography. "
				/>
			</Helmet>

			<div className="about-me-page-div">
				<div id="aboutMeImageDiv">
					<img src={Chey} className="aboutImage"></img>
				</div>
				<div className="aboutPageAboutChey">
					<Typography variant="h1">MEET THE PHOTOGRAPHER</Typography>
					<Typography variant="body1">
						I’m so glad you’re here! <br></br>
						I’m the face behind the lens with a strange name you probably won’t
						pronounce correctly the first time you meet me (thanks mum and dad).
						Growing up you’d always find me killing it doing something creative.
						Never anything science or math related. I’ve always enjoyed
						capturing the in-between moments and documenting life through a lens
						— just ask my friends, but I never really pursued photography or
						thought I could do it until I realised that life’s short and I love
						what I do, so why not share my art with anyone who dares!<br></br>
						<br></br>
						So during (you know what, the big C word) I quit my full-time job,
						walked out in tears not knowing what the heck I’d just done, picked
						up a camera off Facebook marketplace on the way home and the rest is
						history… (PS. How good is Facebook marketplace?)<br></br>
						<br></br> I believe that being a photographer is more than just
						rocking up and taking your photos, it’s about creating real life
						connections, capturing the beautiful and the real moments. When
						looking for the right person to capture your memories, you shouldn’t
						base it off of face value, instead you should be on the hunt for
						someone who can be your best friend for a couple of hours and that
						you can have a good belly laugh with! So if you’re looking for an
						extraverted, bubbly gal to fill those boots, jump over to my contact
						page and let’s create some magic!
					</Typography>
				</div>
			</div>
		</div>
	);
}

export default about;
